import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>I'm Derric Tjon, I'm a self employed and self taught artist. I started to paint about 6 years ago and before that I used to draw. I paint landscapes, seascapes, still life and Star Wars. I love to paint for people to be able to enjoy what I create, not wanting my artworks to be too expensive in the process.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <p><FontAwesomeIcon icon={faInstagram} /> Find me on <a href="https://www.instagram.com/tjon.derric/">Instagram</a>!</p>
    {/*<Link to="/page-2/">Go to page 2</Link>*/}
  </Layout>
)

export default IndexPage
